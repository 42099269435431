import React, { Component } from "react"
import { graphql, StaticQuery } from "gatsby"
import Img from 'gatsby-image';
import {faClock, faUser} from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

class LatestPosts extends Component {
    render() {
        return (
            <div className="latest-posts">
                {
                    this.props.data.allContentfulPost.edges.slice().map(({node: post, index}) => {
                        return (
                          <article className="amztc-post" id="post_1">
                              <div className="row">
                                  <div className="col-md-4">
                                      <Img className="amztc-post-image" fluid={post.realFeaturedImage.localFile.childImageSharp.fluid} />
                                  </div>
                                  <div className="col-md-8">
                                      <h3 className="amztc-post-title">{post.postTitle}</h3>
                                      <div className="amztc-post-info">
                                          <div className="date">
                                              <FontAwesomeIcon icon={faClock} className="la" /> {post.publishDate}
                                          </div>
                                          <div className="author">
                                              <FontAwesomeIcon icon={faUser} className="la" />
                                              {post.postAuthor[0].authorName}
                                          </div>
                                      </div>
                                      <div className="amztc-post-text-preview">
                                          <p>
                                              {post.metaDescription.metaDescription}
                                          </p>
                                      </div>
                                  </div>
                              </div>
                          </article>

                        );
                    })
                }
            </div>
        )
    }
}

export default () => (
    <StaticQuery
        query={graphql`{
 allContentfulPost(sort: {order: DESC, fields: publishDate}, limit: 10) {
      edges {
        node {
          postTitle
          publishDate(formatString: "DD/MM/YYYY, HH:MM")
          realFeaturedImage {
            localFile {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          metaDescription {
            metaDescription
          }
          postAuthor {
            authorName
          }
          slug
        }
      }
  }
  }
`}
        render={(data) => (
            <LatestPosts data={data} />
        )}
    />
)
